// Reusuable classes

.navbar {
    display: flex;
    margin: 10px 0;
    justify-content: end;
}

.container {
    color: $primary-text-color;
    display: block;
    &.landing-page {
        min-height: 100vh;
    }

    .full-container {
        min-height: 100vh;
    }
    
    .row {
        display: flex;
    
        [class*=col] {
            padding: 10px 30px;
        }
    }

    .col-1 {
        flex: 1;
    }
    
    .col-2 {
        flex: 2;
    }
    
    .col-3 {
        flex: 3;
    }
    
    .col-4 {
        flex: 4;
    }
    
    .col-5 {
        flex: 5;
    }
    
    .col-6 {
        flex: 6;
    }
    .col-5 {
        flex: 5;
    }
    .col-7 {
        flex: 7;
    }
    .col-5 {
        flex: 5;
    }
    .col-8 {
        flex: 8;
    }
    .col-9 {
        flex: 9;
    }
    .col-10 {
        flex: 10;
    }
    
    .col-11 {
        flex: 11;
    }
    
    .col-12 {
        flex: 12;
    }    
}

.img-profile {
    width: 180px;
}

.img-center {
    display: block; margin: 0 auto;
}

.bg-primary {
    background-color: $primary-background-color;
}

.bg-secondary {
    background-color: $secondary-background-color;
}

.btn {
    cursor: pointer;
    text-decoration: none !important;
    transition: opacity 0.2s;
    padding: 12px 20px;
    margin: 10px 0;
    border-radius: 22px;
    border: none;
    border-color: none;
    margin: 6px 10px;
    font-size: 16px;
    color: $primary-text-color;
    font-weight: bold;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }

    &.navbar{
        display: inline;
    }
    &.btn-blog {
        border: 1px solid #312F62;
        background: transparent;
    }
    &.btn-right {
        float: right;
    }
    &.btn-login {
        background: #50E3C2;
        background: rgb(255,64,154);
        background: linear-gradient(144deg, #16B4EBa6 0%, #23E73C 100%);
        -webkit-box-shadow: 0px 0px 19px 2px rgba(196,56,239,0.15);
        -moz-box-shadow: 0px 0px 19px 2px rgba(196,56,239,0.15);
        box-shadow: 0px 0px 19px 2px rgba(196,56,239,0.15);
        color: $primary-text-color;
    }
    &.btn-cta {
        font-size: 20px;
        -webkit-box-shadow: 0px 0px 19px 2px rgba(196,56,239,0.15);
        -moz-box-shadow: 0px 0px 19px 2px rgba(196,56,239,0.15);
        box-shadow: 0px 0px 19px 2px rgba(196,56,239,0.15);
        background: rgb(255,64,154);
        background: linear-gradient(144deg, rgba(255,64,154,0.65) 0%, rgba(196,56,239,1) 100%);
    }
}

.text-primary {
    color: $primary-text-color;
}

.text-secondary {
    color: $secondary-text-color;
}

.text-center {
    text-align: center;
}

.text-selected {
    color: $selected-text-color
}

.pt-1 {
    margin: 10px 0;
}

.pt-2 {
    margin: 20px 0;
}

.pt-3 {
    margin: 30px 0;
}

.link {
    color: white;
    text-decoration: none;
    cursor: pointer;
    color: #50E3C2;
}

.head-space {
    margin-top: 118px;
}