.bg-grid-base {
    background-image: url('../images/background.png');
    background-repeat: no-repeat;
    background-size: contain;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

h1 {
    margin-top: 40px;
    font-size: 46px;
}

p {
    margin-top: 20px;
    font-size: 26px;
}
