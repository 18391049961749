.section-title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  margin: 15px 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
}
x
.history-title {
  padding: 10px 0;
  font-weight: bold;
}

.resume-text {
  font-size: 16px;
}

.resume-bullet {
  padding-left: 35px;
}